import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'
import {Row} from 'react-flexa';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogcont from '../components/blogpiece'
// import ContactBlock from '../components/contactblock'
//import Link from "../components/translinkup"
//import Link from "gatsby-plugin-transition-link/AniLink";

const isBrowser = typeof window !== 'undefined';
const ScrollOut  = isBrowser? require('scroll-out') : undefined;

const StyledBackgroundSection = styled(BackgroundImage)`
  width: auto!important;
  min-height: 100vh;
  background-repeat: repeat;
  background-size: 200px;
  color:#ffffff;
`

const HeadLink = styled(Link)`
    position:fixed;
    background-color:#fff;
    color:#303239!important;
    padding:10px 15px 8px;
    font-size:22px;
    border-bottom-right-radius:15px;
    z-index:5; 
    transform-origin: 0 0;
    transition-duration: 400ms;
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(.33,2.1,.73,.69);
    cursor: pointer;
    &:hover{
        transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    }
`
const Container = styled.div`
  margin:auto;
  padding:20px 20px;
  text-align: center;
  h1{
    margin-bottom: 52px;
  }
`

const BlogHolder = styled(Row)`
  .blogIndiv{
    margin-bottom: 50px;
  }
`
let so;
let so1;


export default class IndexPage extends React.Component {  
  componentDidMount(){
    so = new ScrollOut({
        targets: '.blogIndiv',
        threshold: 0.2,
        once: false,
    });
    so1 = new ScrollOut({
      targets: '#send',
      offset: 1,
      once: false,
    });
    }
  componentWillUnmount(){
      if(so){ so.teardown(); }
      if(so1){ so1.teardown(); }
  }
  render() {
    return(  
    <Layout id="blog-list">
      <SEO title="Blog" keywords={['Pixel Friendly','Blog','Tech Blog','Pixel Friendly Blog','Pixel Friendly Digital Agency','Digital Agency','Digital Agency Blog']}/>
      <StyledBackgroundSection fluid={this.props.data.file.childImageSharp.fluid} backgroundColor={`#000000`}>
      <HeadLink to="/">Home</HeadLink>
        <Container>
          <h1>Pixel Friendly Blog</h1>
          <BlogHolder alignContent="stretch" alignItems="stretch" justifyContent="center">
            {this.props.data.blog.edges.map(({ node }, i) => (
                <Blogcont
                key= {'Blog'+i}
                id= {'Blog'+i}
                title = {node.data.Name}
                image = {node.data.BlogImage}
                date = {node.data.Date}
                url = {node.data.url}
                excerpt = {node.data.excerpt}
                />
            ))}
          </BlogHolder>
        </Container>
      </StyledBackgroundSection>
      {/* <ContactBlock/> */}
    </Layout>
    )
  }
}


export const Query = graphql`
  query Query {
    file(relativePath: { eq: "type.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blog: allAirtable(filter: {table: {eq: "Blog"}}, sort: {order: DESC, fields: [data___Date]}) {
      edges{
        node{
          data{
            Name
            excerpt
            url
            Date (formatString: "DD MMMM YYYY")
            BlogImage{
              localFiles{
                  childImageSharp{
                      fluid(maxWidth: 312 maxHeight: 202 cropFocus: CENTER,  quality: 81) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                  }
              }
            }
          }
        }
      }
    }
  }
`