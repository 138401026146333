import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import {Col, Row} from 'react-flexa';
import Img from 'gatsby-image' 
import unified from 'unified'; 
import markdown from 'remark-parse';
import html from 'remark-html';

//import Link from "../components/translink"
//import Link from "gatsby-plugin-transition-link/AniLink";

// const isBrowser = typeof window !== 'undefined';
// const ScrollOut  = isBrowser? require('scroll-out') : undefined;

const Section = styled(Col)`
    position: relative;
    text-align: center;
    color:#303239;
    .blogIndivContain{
        transition: transform .75s cubic-bezier(0.23, 1.68, 0.68, 0.73);
        transition-delay:0.5s;
        transform-origin: 100% 100%;
        transform: scale(0);
        .blogFeat {
            transition: transform .75s cubic-bezier(0.23, 1.68, 0.68, 0.73);
            transition-delay:0.8s;
            transform-origin: 100% 100%;
            transform: scale(0);
        }
    }
    @media (max-width: 720px){
        .blogIndivContain{
            transform: scale(1);
            .blogFeat {
                transform: scale(1);
            }
        }
    }
    &[data-scroll="in"] {
        .blogIndivContain{
            transform: scale(1);
            .blogFeat {
                transform: scale(1);
            }
        }
    }   
    &[data-scroll="out"] {
        .blogIndivContain{
            transform: scale(0);
            .blogFeat {
                transform: scale(0);
            }
        }
    }
    @media (max-width: 1134px){
        max-width:100%;
        width:100%;
        flex-basis: 100%;
        margin-bottom: 20px;
    }
`
const BlogRow = styled(Row)`
    background-color: #fff;
    width: 90%;
    max-width: 600px;
    padding: 0px 0px;
    text-align: left;
    border-radius:20px;
    border-bottom-right-radius: 0;
    margin: auto!important;
    over-flow:hidden;
    box-shadow: -1px -1px 10px 0 rgba(0,0,0,.3);
    height: 240px;
    .right-blog, .left-blog{
        padding-top:10px;
        padding-bottom:10px;
    }
    .left-blog{
        @media (max-width: 760px){
            max-width:90%;
            margin:auto;
            border-bottom: 2px solid #303239;
        }
    }
    .right-blog{
        overflow:hidden;
        height: 100%;
        position:relative;
        .slider-up{transition: all 0.5s cubic-bezier(0.23,1.68,0.68,0.73) 0.8s;}
        @media (max-width: 1134px){
            max-width:80%;
            margin:auto;
        }
        @media (max-width: 760px){
            max-width:90%;
        }
    }
    &:hover{
        .right-blog{
            .slider-up{
                transform: translateY(-40px);
                transition-delay:0s;
            }
        }
        .blogFeat {
            transform: scale(1.1)!important;
            transition-delay:0s!important;
        }
    }
    @media (max-width: 768px){
        height:auto;
    }
`
const BlogFeature = styled(Img)`
    border-radius: 20px;
    overflow:hidden;
    border-bottom-right-radius: 0;
    margin-left: -28px;
    margin-top: -28px;
    box-shadow: 2px 2px 14px 0 rgba(0,0,0,.3);
    @media (max-width: 760px){
        max-width:100%;
        margin:auto;
    }
`
const BlogHeader = styled.div`
    h3{
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 24px;
    }
`
const BlogRead = styled.div`
    position:absolute;
    background: #303239;
    color:#fff;
    bottom:-40px;
    right:0px;
    padding: 12px 9px 9px 12px;
    border-top-left-radius: 14px;
`


const BlogExcerpt = styled.div`
`
const BlogDate = styled.div`
    font-size: 24px;
    margin-top: 16px;
    text-align: center;
`

// let so;

export default class BlogBlock extends React.Component {
    // componentDidMount() {
    //     so = new ScrollOut({
    //         targets: '.blogIndiv',
    //         threshold: 0.4,
    //         once: false,
    //     });
    // }
    // componentWillUnmount() {
    //     if(so){ so.teardown(); }
    // }
    render() { 
        return (
            <Section md={12} lg={6} gutter="12px" className="blogIndiv section-wrapper">
            <Link to={'/blog/'+this.props.url} >
                <BlogRow className="blogIndivContain">
                    <Col xs={12} md={6}className="left-blog">
                        <BlogFeature fluid={this.props.image.localFiles[0].childImageSharp.fluid} className="blogFeat" alt={this.props.title} title={this.props.title}/>
                        <BlogDate>
                            {this.props.date}
                        </BlogDate>
                    </Col>
                    <Col xs={12} md={6}className="right-blog">
                        <BlogHeader className="slider-up">
                            <h3>{this.props.title}</h3>
                        </BlogHeader>
                        <BlogExcerpt className="slider-up"
                            dangerouslySetInnerHTML={{
                                __html: unified()
                                .use(markdown)
                                .use(html)
                                .processSync(this.props.excerpt)
                            }}
                            />
                        <BlogRead className="slider-up">Read More</BlogRead>
                    </Col>
                </BlogRow>
            </Link>
        </Section>
        )
    }
}